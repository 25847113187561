import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';

import imgRun from '../Assets/icons/run.png';
import imgAccess from '../Assets/icons/access.png';
import imgPhoto from '../Assets/icons/photo.png';
import imgPhone from '../Assets/icons/phone.png';
import imgHands from '../Assets/icons/hands.png';


import ConfigContext from '../ConfigContext';


function safety_images(texts) {
	if (texts.length <= 2) {
		return (
			<div className="d-flex flex-wrap">
				<div className="d-flex w-50 align-items-center">
					<div><img src={imgPhoto} alt="" /></div>
					<div className="pl-2">{texts[0]}</div>
				</div>
				<div className="d-flex w-50 align-items-center">
					<div><img src={imgHands} alt="" /></div>
					<div className="pl-2">{texts[1]}</div>
				</div>
			</div>
		);
	} else {
		return (
			<div>
				<div className="d-flex flex-wrap">
					<div className="d-flex w-50 align-items-center">
						<div><img src={imgAccess} alt="" /></div>
						<div className="pl-2">{texts[0]}</div>
					</div>
					<div className="d-flex w-50 align-items-center">
						<div><img src={imgPhoto} alt="" /></div>
						<div className="pl-2">{texts[1]}</div>
					</div>
				</div>
				<br />
				<div className="d-flex flex-wrap">
					<div className="d-flex w-50 align-items-center">
						<div><img src={imgPhone} alt="" /></div>
						<div className="pl-2">{texts[2]}</div>
					</div>
					<div className="d-flex w-50 align-items-center">
						<div><img src={imgHands} alt="" /></div>
						<div className="pl-2">{texts[3]}</div>
					</div>
				</div>
			</div>
		);

	}
}

function contact_persons_table(texts) {
	if (texts.length === 2) {
		return(
			<div className="d-flex flex-wrap">
				<div className="mt-4 w-50">
					<table className="table-important">
						<tbody>
							{parse(texts[0])}
						</tbody>
					</table>
				</div>
				<div className="mt-4 w-50">
					{parse(texts[1])}
				</div>
			</div>
		);
	}
}

function emergency_plan_table(texts) {
	if (texts.length === 3) {
		return(
			<div className="d-flex flex-wrap">
				<div className="w-50">
					{parse(texts[0])}
					<div className="table-emergency">{parse(texts[1])}</div>
				</div>
				<div className="d-flex align-items-center mt-4">
					<div><img src={imgRun} alt="" /></div>
					<div className="pl-2">{parse(texts[2])}</div>
				</div>
			</div>
		);
	}
}


export default function Page(props) {

	const {texts, setActiveView} = props;

	const { currentLanguage } = React.useContext(ConfigContext);

	const language = texts;


	let compliance_safety_texts = [];
	if (language[currentLanguage].texts.compliance_section_2 !== "") {
		compliance_safety_texts = JSON.parse(language[currentLanguage].texts.compliance_section_2);
	}

	let compliance_contact_texts = [];
	if (language[currentLanguage].texts.compliance_section_4 !== "") {
		compliance_contact_texts = JSON.parse(language[currentLanguage].texts.compliance_section_4);
	}

	let compliance_emergency_texts = [];
	if (language[currentLanguage].texts.compliance_section_5 !== "") {
		compliance_emergency_texts = JSON.parse(language[currentLanguage].texts.compliance_section_5);
	}


	return (
		<Container className="mt-4 flex-grow-1">
			<Row>
				<Col className="text-left d-flex">
					<div><button type="button" className="btn btn-secondary btn-sm" onClick={() => setActiveView("disclosure")}>&lt;</button></div>
					<div className="ml-3"><h4>{parse(language[currentLanguage].titles.compliance_main)}</h4></div>
				</Col>
			</Row>
			<Row className="pt-4">
				<Col>
					<section id="section-1" className="mb-5">
						{
							language[currentLanguage].titles.compliance_section_1 !== "" &&
								<h6>{parse(language[currentLanguage].titles.compliance_section_1)}</h6>
						}
						{
							language[currentLanguage].texts.compliance_section_1 !== "" &&
								<div>{parse(language[currentLanguage].texts.compliance_section_1)}</div>
						}
					</section>
					<section id="section-2" className="mb-5">
						{
							language[currentLanguage].titles.compliance_section_2 !== "" &&
								<h6>{parse(language[currentLanguage].titles.compliance_section_2)}</h6>
						}
						{
							safety_images(compliance_safety_texts)
						}
					</section>
					<section id="section-3" className="mb-5">
						{
							language[currentLanguage].titles.compliance_section_3 !== "" &&
								<h6>{parse(language[currentLanguage].titles.compliance_section_3)}</h6>
						}
						{
							language[currentLanguage].texts.compliance_section_3 !== "" &&
								<div>{parse(language[currentLanguage].texts.compliance_section_3)}</div>
						}
					</section>
					<section id="section-4" className="mb-5">
						{
							language[currentLanguage].titles.compliance_section_4 !== "" &&
								<h6>{parse(language[currentLanguage].titles.compliance_section_4)}</h6>
						}
						{
							contact_persons_table(compliance_contact_texts)
						}
					</section>
					<section id="section-5" className="mb-5">
						{
							language[currentLanguage].titles.compliance_section_5 !== "" &&
								<h6>{parse(language[currentLanguage].titles.compliance_section_5)}</h6>
						}
						{
							emergency_plan_table(compliance_emergency_texts)
						}
					</section>
				</Col>
			</Row>
			<Row>
				<Col className="text-center mt-4">
					<button type="button" onClick={() => setActiveView("signature")} className="btn btn-primary">{language[currentLanguage].buttons.accept}</button>
				</Col>
			</Row>
		</Container>
	);

}